import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import Signup from "./pages/signup";

import "./App.css";
import logo from "./images/gsch.webp";

function App() {
  return (
    <Router>
      <div className="App">
      <img src={logo} alt="App Logo" className="app-logo" />        
      <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/login">
            <Login />
          {/* </Route>
          <Route path="/signup">
            <Signup />*/}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
