const en = {
  GREETINGS: {
    SIGNUP: "Sign Up",
    LOGIN: "Вход в систему ГЛОБАЛ-ID",
  },

  FORM_FIELDS: {
    NAME: "Имя",
    EMAIL: "E-mail",
    PASSWORD: "Пароль",
    RETYPE_PASSWORD: "Введите пароль заново",
    SIGNUP: "Войти",
    LOGIN: "Login",
    SIGNUP_ALT_TEXT: "Existing user?",
    LOGIN_ALT_TEXT: "New user?",
  },

  ERRORS: {
    EMPTY_EMAIL: "Требуется E-mail",
    INVALID_EMAIL: "Неверный E-mail",
    EMPTY_PASSWORD: "Требуется пароль",
    PASSWORD_MISMATCH: "Passwords do not match",
    AUTH: "Неверный логин или пароль",
  },
};

export default en;
